<template>
  <v-container id="trading_panel" fluid tag="section">
    <v-row
      ><v-col>
        <bread-crumbs :breadcrumbs_items="breadcrumbs_items">
        </bread-crumbs></v-col
    ></v-row>
    <v-row>
      <v-col cols="12" xs="12" lg="9" ref="cp">
        <div id="kline" :style="style"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get_candlestick } from "@/api";

import * as klinecharts from "klinecharts";

import SymbolTickerList from "@/views/dashboard/component/SymbolTickerList";
import BreadCrumbs from "@/views/dashboard/component/BreadCrumbs";

export default {
  components: {
    SymbolTickerList,
    BreadCrumbs,
  },

  destroyed() {
    clearInterval(this.timer);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setChartWH);
  },
  mounted: function () {
    this.$nextTick(() => {
      this.chart = klinecharts.init("kline");
    });
    window.addEventListener("resize", this.setChartWH);
    this.setChartWH();
    this.updateKLine();

    this.timer = setInterval(() => {
      this.updateKLine();
    }, 5000);
  },

  data() {
    return {
      chart: undefined,
      colors: {
        colorBack: "#fff",
        colorGrid: "#444",
        colorText: "#fff",
      },
      style: "",

      breadcrumbs_items: [
        {
          text: "Market",
          disabled: false,
          href: "/pages/market",
        },
        {
          text: this.$route.params.symbol,
          disabled: false,
          href: `/pages/market/${this.$route.params.symbol}`,
        },
      ],
      symbol: this.$route.params.symbol,
      timer: -1,
    };
  },

  methods: {
    updateKLine() {
      get_candlestick({
        symbol: this.symbol,
        accountType: "BINANCE_USDT_FUTURE",
        limit: 200,
      }).then((candlestick) => {
        const mapped_data = candlestick.map((item) => {
          item.timestamp = item.closeTime;
          return item;
        });

        this.chart.applyNewData(mapped_data);
      });
    },
    genTickerList(rawData) {
      if (rawData === null || rawData === undefined) {
        return [];
      }
      return Object.keys(rawData)
        .sort()
        .map((item) => rawData[item]);
    },
    setChartWH() {
      const width = this.$refs.cp.clientWidth;
      const height = window.innerHeight * 0.6;
      this.style = `width:${width}px; height:${height}px;`;
    },
  },
};
</script>
